import { graphql } from "gatsby";
import React from "react";

import ArticlesGrid from "../components/articles-grid";
import { IArticleData } from "../components/articles-grid/articles-grid";
import CollapseTags from "../components/collapse-tags/collapse-tags";
import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import FiltersApplied from "../components/filters-applied";
import Footer from "../components/footer";
import GetInTouch from "../components/get-in-touch";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import HeroTags from "../components/hero-tags/hero-tags";
import { breadCrumbSchema } from "../components/html-schema-snippets";
import {
  IBreadCrumb,
  IBreadCrumbSchema
} from "../components/html-schema-snippets/breadcrumb-schema";
import Layout from "../components/layout";
import NoItemsFound from "../components/no-items-found/no-items-found";
import PageTop from "../components/page-top";
import Pagination from "../components/pagination";
import {
  ArticlesGridWrapper,
  IBlogArticleData,
  PageTopCustom
} from "../pages/latest";
import getProfile from "./utils/getProfile";

export interface IAllTaxonomyTermLatest {
  edges: ITaxonomyTermLatest[];
}

interface ITaxonomyTermLatest {
  name: string;
  relationships: {
    node__blog_article: IBlogArticleData[] | null;
  };
}

interface ITaxonomyTermLatest {
  node: {
    name: string;
    path: {
      alias: string;
    };
  };
}

interface IPageContext {
  skip: number;
  limit: number;
  current: number;
  name: string;
  slug: string;
  total: number;
}

interface INodeData {
  node: IBlogArticleData;
}

interface ILatestTagsProps {
  nodeLatestOverview: {
    created: string;
    changed: string;
    title: string;
    field_hero_heading: string;
    field_meta_description: string;
  };
  allNodeBlogArticle: {
    edges: INodeData[];
  } | null;
  allTaxonomyTermLatest: IAllTaxonomyTermLatest;
}

const LatestTags = ({
  data,
  pageContext
}: {
  data: ILatestTagsProps;
  pageContext: IPageContext;
}) => {
  const paginationPage = pageContext.current + 1;

  const breadcrumbs: IBreadCrumb[] = [
    {
      name: "Latest",
      path: "/latest"
    },
    {
      name: pageContext.name,
      path: pageContext.slug
    }
  ];

  let latestNodes: INodeData[] = [];
  let latestArticles: IBlogArticleData[] = [];
  let schemaJson: IBreadCrumbSchema[] = [];

  // If the pagination page value is greater than the 1st page, add a breadcrumb
  if (paginationPage > 1) {
    breadcrumbs.push({
      name: `Page ${paginationPage}`,
      path: `${pageContext.slug}/${paginationPage}`
    });
  }

  // Now set the schema JSON object
  schemaJson = [ breadCrumbSchema(breadcrumbs) ];

  // If there is allNodeProject, set the latestNodes variable
  if (data.allNodeBlogArticle) {
    latestNodes = data.allNodeBlogArticle.edges;

    latestArticles = latestNodes.map(projectNode => {
      return projectNode.node;
    });
  }

  return (
    <Layout className="latest latest-by-tag">
      <>
        <HelmetWrapper
          title={paginationPage === 1 ?
            `${pageContext.name} articles` :
            `${pageContext.name} articles page ${paginationPage}`}
          description={`Specific ${pageContext.name} articles which form part of our Latest section`}
          updatedAt={latestArticles[ 0 ] ? latestArticles[ 0 ].changed : ""}
          canonicalPath={pageContext.slug}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="header"
        >
          <Header
            initialLogo
            initialTheme="light"
          />

          <PageTopCustom id="hero">
            <PageTop
              gradient="lightPinkPurple"
              height="small"
            >
              <FullWidthColumnWrapper className="top-content">
                <p className="heading">
                  {data.nodeLatestOverview.field_hero_heading}
                </p>

                <HeroTags
                  initialTheme="light"
                  featuredPath="/latest"
                  tags={data.allTaxonomyTermLatest.edges.map(tag => {
                    return {
                      name: tag.node.name,
                      path: tag.node.path.alias
                    };
                  })}
                />
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>

          <CollapseTags
            root="/latest"
            rootTerm="Everything"
            tags={data.allTaxonomyTermLatest.edges}
          />

          <FiltersApplied
            filter={pageContext.name}
            clearLink="/latest"
            type="post"
          />
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {`${pageContext.name} articles`}
          </h1>

          {latestArticles.length === 0 ? (
            <NoItemsFound>
              <p>
                <strong>
                  Oops
                </strong>
                . No articles were found.
              </p>
            </NoItemsFound>
          ) : (
            <>
              <ArticlesGridWrapper>
                <ArticlesGrid
                  articles={latestArticles.map(post => {
                    const profile = getProfile(post.relationships.author);

                    const articleObj: IArticleData = {
                      created: post.created,
                      path: post.path.alias,
                      title: post.title,
                      author: profile.field_full_name,
                      cover: post.relationships.field_cover_image
                    };

                    return articleObj;
                  })}
                />
              </ArticlesGridWrapper>

              <aside>
                <FullWidthColumnWrapper>
                  <Pagination
                    current={pageContext.current}
                    total={pageContext.total}
                    limit={pageContext.limit}
                    slug={pageContext.slug}
                  />
                </FullWidthColumnWrapper>
              </aside>
            </>
          )}
        </main>

        <footer role="contentinfo">
          <GetInTouch />

          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default LatestTags;

export const latestTagsQuery = graphql`
  query($slug: [String], $skip: Int = 0, $limit: Int = 18, $tagLimit: Int = 4) {
    nodeLatestOverview {
      created
      changed
      title
      field_hero_heading
      field_meta_description
    }
    allNodeBlogArticle(
      limit: $limit
      skip: $skip
      filter: {
        relationships: {
          field_latest_tags: { elemMatch: { path: { alias: { in: $slug } } } }
        }
      }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          ...getLatestData
        }
      }
    }
    ...getLatestTags
  }
`;
